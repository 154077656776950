body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.chakra-ui-light {
  background-color: #ffffff;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header :first-of-type div {
  padding-top: 8px;
  padding-bottom: 8px;
}

/* Don't show hamburger menu button on global header v2 component in mobile */
header .chakra-button {
  display: none;
}

h2 {
  padding-top: 100px;
}

@media only screen and (max-width: 1135px) {
  h2 {
    padding-top: 50px;
  }
}

.content-wrapper {
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
}

@media only screen and (max-width: 1135px) {
  .content-wrapper {
    flex-direction: column-reverse;
    justify-content: flex-end;
    min-height: auto;
  }
}

.form-content-wrapper {
  width: 60%;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  color: #3A3B3D;
}

@media only screen and (max-width: 1135px) {
  .form-content-wrapper {
    width: 100%;
    align-items: flex-start;
    height: auto;
  }
}

div #banner-container {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

div #banner {
  width: 100%;
  background-size: 100%;
  background-position: center;
}

.vectors-container {
  width: 40%;
  background-image: url(https://www.mysgi.ca/css/../images/mainbanner.jpg);
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  background-size: cover;
  z-index: -1;
  position: relative;
}

.vectors-container img {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 1135px) {
  .vectors-container {
    width: 100%;
    height: auto;
    background-position-y: center;
  }
}

.vectors picture:first-of-type img {
  right: 15%;
  bottom: -5%;
  width: 200%;
  z-index: 1;
}

.vectors img {
  position: absolute;
  right: 0;
}

@media only screen and (max-width: 1135px) {
  .vectors picture:first-of-type img {
    right: 0;
    top: 95%;
    width: 100%;
    z-index: 1;
  }
}

.vectors picture:nth-of-type(2) img {
  bottom: -25%;
  width: 150%;
}

@media only screen and (max-width: 1135px) {
  .vectors picture:nth-of-type(2) img {
    right: 0;
    top: 90%;
    width: 100%;
  }
}

.carousel-wrapper {
  height: 100%;
}

/* form-content-wrapper takes the height of largest carousel child, so hide what is not active on screen */
.carousel-child {
  width: 0;
  height: 0;
}

.carousel-child.active {
  width: 100%;
  height: 100%;
}